.footer {
  background-color: $c-text-aux;
  color: $white;

  &__inner {
    display: flex;
    flex-wrap: wrap;

    padding-top: rem(40);
    padding-bottom: rem(20); }

  &__logo {
    margin-right: rem(65);
    @include md {
      margin-right: 0;
      margin-bottom: rem(30); } }

  &__nav {
    flex-grow: 1;
    display: flex;
    .nav:first-child {
      margin-right: rem(115); }
    @include md {
      margin-bottom: rem(25); } }

  &__copy {
    width: 100%;
    margin-top: rem(75);
    color: rgba($white, .2);
    font-size: em(16); }

  &__right {
    flex-grow: 1;
    display: flex;
    @include md {
      width: 100%;
      display: block; } } }
