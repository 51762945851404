.container {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width;
  // static values fallback
  padding-left: $container-padding-static;
  padding-right: $container-padding-static;
  // css variables
  padding-left: $container-padding;
  padding-right: $container-padding; }

.grid {
  display: flex;
  flex-wrap: wrap;
  // static values fallback
  margin-left: -$grid-gap / 2;
  margin-right: -$grid-gap / 2;
  margin-bottom: -$grid-gap;
  // css variables
  margin-left: $half-gap-negative;
  margin-right: $half-gap-negative;
  margin-bottom: $gap-negative;
  &--no-bottom-gap {
    margin-bottom: 0; }
  &--no-bottom-gap-all {
    margin-bottom: 0;
    .col {
      margin-bottom: 0; } } }

.col {
  width: 100%;
  // static values fallback
  padding-left: $grid-gap / 2;
  padding-right: $grid-gap / 2;
  margin-bottom: $grid-gap;
  // css variables
  padding-left: $half-gap;
  padding-right: $half-gap;
  margin-bottom: $gap;
  &--no-bottom-gap {
    margin-bottom: 0; } }
