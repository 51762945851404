.title {
  // +css-lock(21, 45, 320, 1399, font-size)
  display: block;
  font-weight: 400;
  line-height: 1.2;

  &--h1 {
    font-size: em(70);
    text-transform: uppercase;
    @include xs {
      font-size: em(50, $base-fz-sm); }
    @include xxs {
      font-size: em(30, $base-fz-sm); } }

  &--h2 {
    font-size: em(30); }

  &--h4 {
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase; }

  &--ttu {
    text-transform: uppercase; } }
