.logo {
  $this: &;

  display: flex;
  align-items: center;

  @include xl {
    font-size: 13px; }
  @include lg {
    font-size: 12px; }
  @include md {
    font-size: 11px; }
  @include sm {
    font-size: 8px; }

  &__img {
    margin-right: em(25);
    width: em(120);
    flex: 0 0 em(120); }

  &__content {
    display: block; }

  &__title {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;

    background-color: $accent;
    color: $white;

    padding: .2em .7em;
    margin-bottom: em(10); }

  &__text {
    display: block; }

  &--footer {
    #{$this}__img {
      width: em(90);
      flex: 0 0 em(90); }

    #{$this}__title {
      background-color: #464646; } } }
