.btn {
  display: inline-block;
  text-align: center;
  color: $white;

  text-transform: uppercase;
  font-size: em(18);

  border: 2px solid $white;
  border-radius: 5px;

  min-width: 210px;
  padding: 1em 1em .75em;

  @include tr(color .3s);

  @include hover {
    color: $c-text;
    background-color: $white; }
  &:active {
    color: $c-text;
    background-color: #c8c8c7; }

  &--accent {
    color: $accent;
    border-color: $accent;
    @include hover {
      color: $white;
      background-color: $accent; }
    &:active {
      color: $white;
      background-color: #a61704; } }

  &--full {
    width: 100%;
    min-width: 0; } }
