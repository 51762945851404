.contacts-block {
  &__title {
    font-weight: 700;
    font-size: em(24);
    margin-bottom: rem(15); }

  &__contacts {
    margin-bottom: rem(15); } }

.contacts {
  &__item {
    position: relative;
    padding-left: rem(30);

    @include notlast {
      margin-bottom: rem(10); }

    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } }

  &__icon {
    position: absolute;
    top: .3rem;
    left: 0;
    fill: $accent;
    font-size: .8em; } }

.map {
  position: relative;
  height: 100%;
  @include sm {
    padding-top: 50%; }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }
