.nav {
  $this: &;

  &__item {
    display: inline-block;
    @include notfirst {
      margin-left: rem(20);
      @include lg {
        margin-left: 0; } }

    @include lg {
      display: block;
      @include notlast {
        margin-bottom: rem(20); } } }

  &__link {
    position: relative;
    display: inline-block;

    @include tr(color .3s);
    @include before {
      position: absolute;
      bottom: -.5em;
      left: 0;
      right: 0;
      border-top: 2px solid currentColor;
      @include hidden;
      @include tr(opacity .3s, visibility .3s); }

    @include hover {
     @extend #{$this}__link--current; }

    &--current {
      &:not(#{$this}__link--static) {
        color: $accent;
        @include before {
          @include visible; } } }

    &--static {
      text-decoration: underline; } }

  &--footer {
    #{$this}__item {
      display: block;
      margin-left: 0 !important;
      @include notlast {
        margin-bottom: rem(10); } }

    #{$this}__link {
      @include hover {
        @include before {
 } } } }          // display: none

  &--aside {
    #{$this}__item {
      display: block;
      margin-left: 0 !important;
      @include notlast {
        margin-bottom: rem(20);
        @include xs {
          margin-right: rem(10); } }

      @include xs {
        display: inline-block; } } } }
