@include font('firasans', 'firasans-light-webfont', 300);
@include font('firasans', 'firasans-regular-webfont', 400);
@include font('firasans', 'firasans-medium-webfont', 500);
@include font('firasans', 'firasans-bold-webfont', 700);

@include font('firasans', 'firasans-italic-webfont', 400, italic);

@mixin reg {
  font-family: 'firasans', sans-serif; }

