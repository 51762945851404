@for $i from 1 through $cols-number {
  .col {
    &--#{$i} {
      width: percentage($i/$cols-number); }
    &--xxs-#{$i} {
      @include xxsmin {
        width: percentage($i/$cols-number); } }
    &--xs-#{$i} {
      @include xsmin {
        width: percentage($i/$cols-number); } }
    &--sm-#{$i} {
      @include smmin {
        width: percentage($i/$cols-number); } }
    &--md-#{$i} {
      @include mdmin {
        width: percentage($i/$cols-number); } }
    &--lg-#{$i} {
      @include lgmin {
        width: percentage($i/$cols-number); } }
    &--xl-#{$i} {
      @include xlmin {
        width: percentage($i/$cols-number); } } } }
