.slider {
  &__wrap {
    position: relative;

    button[data-action="stop"], button[data-action="start"], {
      display: none; }

    .tns-nav {
      button {
        display: inline-block;
        vertical-align: middle;
        position: relative;

        width: rem(16);
        height: rem(16);

        border-radius: 50%;
        border: 1px solid  $white;

        @include notlast {
          margin-right: rem(20); }

        @include before {
          @include vcenter;

          display: none;

          border-radius: 50%;
          background-color: $accent;

          width: rem(6);
          height: rem(6); }

        &.tns-nav-active {
          @include before {
            display: block; } } } } }

  &--hero {
    &__wrap {} } }

