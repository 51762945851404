.header {
  border-bottom: 3px solid  $accent;
  position: relative;
  z-index: 10;

  &__top {
    background-color: $accent;
    color: $white;
    font-size: em(16);
    position: relative;
    z-index: 2; }

  &__bottom {
    position: relative;
    z-index: 1;
    @include before {
      @include coverdiv;
      z-index: 2;
      background-color: $white; } }

  &__logo {
    position: relative;
    z-index: 3; }

  &__info {
    display: flex;
    align-items: center; }

  &__time {
    margin-right: rem(20); }

  &__top-in {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $header-top-height;
    padding-top: rem(5);
    padding-bottom: rem(5); }

  &__bottom-in {
    display: flex;
    align-items: center;

    padding-top: rem(15);
    padding-bottom: rem(15); }

  &__nav {
    margin-left: auto;
    position: relative;
    z-index: 3;
    @include lg {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      right: 0;
      background-color: $white;
      text-align: center;
      padding: rem(15);
      transform: translate(0, -100%);
      @include ready {
        @include tr(transform .5s); }
      &--open {
        transform: translate(0, 0); } } }

  &__burger {
    margin-left: auto;
    position: relative;
    @include lgmin {
      display: none; } }

  &--no-bdb {
    border-bottom: 0; } }

.lang {
  $this: &;

  &__item {
    display: inline-block;
    &--current {
      text-decoration: underline; } }

  &__link {
    @include hover {
      @extend #{$this}__item--current; } } }

.burger {
  display: block;
  width: rem(40);
  height: rem(40);
  position: relative;
  z-index: 15;
  span {
    display: block;
    &, &::after, &::before {
      width: 100%;
      height: 4px;
      background-color: currentColor;
      backface-visibility: hidden;
      border-radius: 2px;
      @include tr(transform .3s); }
    &::after, &::before {
      content: "";
      position: absolute;
      left: 0; }
    &::after {
      top: 5px; }
    &::before {
      bottom: 5px; } }
  // &--active
  //   span
  //     background-color: transparent
  //     &::before
  //       transform: rotate(45deg) translate(-7px,-5px)
  //     &::after
 }  //       transform: rotate(-45deg) translate(-6px,4px)
