$cols-number:                  12;
$container-max-width:          1440px;

$container-padding-static:     rem(15);
// $container-padding-static-xl:  15px
// $container-padding-static-lg:  15px
// $container-padding-static-md:  15px
// $container-padding-static-sm:  10px
// $container-padding-static-xs:  10px
// $container-padding-static-xxs: 10px

$grid-gap:                     rem(30);
// $grid-gap-xl:                  20px
// $grid-gap-lg:                  24px
// $grid-gap-md:                  20px
// $grid-gap-sm:                  16px
// $grid-gap-xs:                  20px
// $grid-gap-xxs:                 20px
