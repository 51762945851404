.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: em(24);
  color: #b7b7b7;

  &__link {
    min-width: rem(60);
    height: rem(60);
    border-radius: 5px;
    border: 1px solid transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    @include tr(color .3s, border-color .3s);

    @include hover {
      color: $accent;
      border-color: $accent; }

    &--prev {
      transform: scaleX(-1); } }
  &__icon {
    font-size: .5em; } }

