.breadcrumbs {
  display: flex;
  align-items: center;

  &__item {
    display: inline-flex;
    align-items: center;
    @include notlast {
      margin-right: rem(10); } }

  &__icon {
    font-size: .7em; }

  &__link {
    @include tr(color .3s);
    @include hover {
      color: $accent; } } }

