.card {
  $this: &;

  display: flex;
  flex-direction: column;
  height: 100%;

  &__img {
    @include cover;
    background-color: $light;
    border-radius: 5px;
    padding-top: percentage(245 / 330); }

  &__content {
    padding: rem(25) 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column; }

  &__title {
    margin-bottom: rem(10);
    flex-grow: 1; }

  &__text {
    color: $c-text-aux;
    margin-bottom: rem(10); }

  &__date {
    color: #959595;
    font-size: em(16);
    margin-bottom: rem(10); }

  &--hor {
    @include xxsmin {
      flex-direction: row; }

    #{$this}__img {
      @include xxsmin {
        width: calc(50% - #{rem(15)});
        padding-top: 32%; } }

    #{$this}__content {
      @include xxsmin {
        width: calc(50% + #{rem(15)});
        padding-left: rem(15); } } } }
