.article {
  strong {
    font-weight: 500; }

  p, ul, ol {
      margin-bottom: rem(20); }

  ul {
    &:not(.list) {
      li {
        &::before {
          content: '-';
          display: inline-block;
          margin-right: .3em; } } } } }


