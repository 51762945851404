.section {
  padding-top: rem(65);
  padding-bottom: rem(85);

  @include sm {
    padding-top: rem(50, $base-fz-sm);
    padding-bottom: rem(50, $base-fz-sm); }

  &__top {
    padding-bottom: rem(20);
    border-bottom: 2px solid $light;
    margin-bottom: rem(55);
    &--flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; } }

  &__img {
    margin-bottom: rem(50); }

  &__pagination {
    margin-top: rem(50);
    @include sm {
      margin-top: rem(30, $base-fz-sm); } } }

.section-link {
  font-size: em(16);
  text-decoration: underline;
  text-transform: uppercase;
  @include tr(color .3s);
  @include hover {
    color: $accent; } }
