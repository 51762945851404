.hero {
  &__slider {
    .tns-nav {
      position: absolute;
      z-index: 3;
      bottom: rem(45);
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      max-width: $container-max-width;
      padding: 0 $container-padding-static; } }

  &__slide {
    color: $white;
    position: relative;

    padding-top: rem(170);
    padding-bottom: rem(160);

    @include before {
      @include coverdiv;
      z-index: 1;
      background-color: rgba($black, .75); } }



  &__title {
    margin-bottom: rem(15); }

  &__subttl {
    font-size: em(30);
    font-weight: 300;
    margin-bottom: rem(45);
    @include xxs {
      font-size: em(20, $base-fz-sm); } }

  &__content {
    max-width: 740px;
    position: relative;
    z-index: 2; }

  &__bg {
    @include coverdiv;
    @include cover;
    z-index: 0;
    background-color: $c-text-aux;
    display: none;
    .tns-slide-active & {
      display: block; } } }

