.gallery {
  $this: &;

  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-15);
  margin-right: rem(-15);

  @include xxs {
    margin-left: 0;
    margin-right: 0; }

  &__item {
    width: calc(25% - #{rem(30)});
    margin-left: rem(15);
    margin-right: rem(15);
    margin-bottom: rem(30);
    padding-top: 15%;

    @include md {
      width: calc(33.33333% - #{rem(30)});
      padding-top: 25%; }

    @include xs {
      width: calc(50% - #{rem(30)});
      padding-top: 60%; }

    @include xxs {
      width: 100%;
      margin-left: 0;
      margin-right: 0; } }

  &--lg {
    @include xs {
      margin-left: 0;
      margin-right: 0; }

    #{$this}__item {
      width: calc(50% - #{rem(30)});
      margin-bottom: rem(40);
      padding-top: 0 !important;
      @include xs {
        width: 100%;
        margin-left: 0;
        margin-right: 0; } } } }

.gallery-item {
  $this: &;

  @include cover;
  display: block;
  background-color: $light;
  border-radius: 5px;

  &__img {
    @include cover;
    display: block;
    background-color: $light;
    border-radius: 5px;
    padding-top: 55%; }

  &--lg {
    position: relative;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;

    #{$this}__img {
      position: relative;
      display: block;
      border-radius: 5px;
      overflow: hidden;

      color: $white;
      margin-bottom: rem(25);

      @include before {
        @include coverdiv;
        background-color: rgba($black, .65); } }

    #{$this}__icon {
      font-size: rem(75);
      z-index: 1;
      @include vcenter; } } }

