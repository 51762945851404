html {
  font-size: $base-fz + px;
  line-height: 1.4;
  color: $c-text;
  @include reg;
  @include sm {
    font-size: $base-fz-sm + px; } }

body {
  background-color: $white;
  min-width: 320px;
  @include no-scroll; }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  $this: &;
  &--loaded:not(#{$this}--no-fade-in) {
    animation: fadeIn 1s; } }

.main {
  flex-grow: 1;
  &__breadcrumbs {
    padding-top: rem(30); } }

.out {
  height: (100 / 0.7) * 1vh;
  display: flex;
  flex-direction: column; }

.link {
  $this: &;

  color: $accent;
  font-size: em(16);

  display: inline-flex;
  align-items: center;
  position: relative;

  &:not(#{$this}--no-hover) {
    @include hover {
      text-decoration: underline; } }

  &__icon {
    font-size: .6em;
    margin-left: 1em; } }

.list {
  li {
    position: relative;
    padding-left: .8em;

    &::before {
      content: '';
      display: inline-block;
      // color: $accent
      background-color: $accent;

      width: rem(5);
      height: rem(5);

      margin-right: .5em;
      position: absolute;
      top: .6em;
      left: 0; } } }

.close {
  @include icon-close(1rem, 1px, currentColor);
  @include tr(color .3s);
  // +hover
 }  //// color: $accent


